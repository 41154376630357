import { Jul2624 } from "./jul2024/7-26-24"
import { Jun2124 } from "./jun2024/6-21-24"
import { Feb1024 } from "./feb2024/2-10-24"
import { Jan0324 } from "./jan2024/1-3-24"
import { Nov1323 } from "./nov2023/11-13-23"
import { Nov1523 } from "./nov2023/11-15-23"
import { Nov1623 } from "./nov2023/11-16-23"
import { Nov1723 } from "./nov2023/11-17-23"
import { Nov2823 } from "./nov2023/11-28-23"
import { IPost } from "./types"

export const Posts: IPost[] = [
    Jul2624,
    Jun2124,
    Feb1024,
    Jan0324,
    Nov2823,
    Nov1723,
    Nov1623,
    Nov1523,
    Nov1323,
]